<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="parent_page">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Code" :error-messages="errors.code" v-model="documents_type.code"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" :error-messages="errors.name"  v-model="documents_type.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox label="Active" v-model="documents_type.is_active"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created" :value="createdAt" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Updated" :value="updatedAt" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn class="warning" @click="handleUpdate">Update</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn class="error" @click="handleDelete">Delete</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import DocumentsType from "../../../models/admin/DocumentsType";
import moment from "moment";
import {mapMutations} from "vuex";

export default {
    name: "documents-type-show",
    components: {
        AdminDashboardLayout,
    },
    data: function () {
        return {
            parent_page: {name: 'admin.documents_types.index'},
            documents_type: {},
            errors: {},
        }
    },
    computed: {
        createdAt() {
            return this.documents_type.created_at ? moment(this.documents_type.created_at).format('LLL') : ''
        },
        updatedAt() {
            return this.documents_type.updated_at ? moment(this.documents_type.updated_at).format('LLL') : ''
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        handleUpdate() {
            this.documents_type.save().then(async () => {
                this.errors = {}
                this.documents_type = await DocumentsType.find(this.$route.params.id)
                this.showSnackBar(this.$t('messages.success'))
            }).catch((e) => {
                this.errors = e.response.data.errors
            })
        },
        handleDelete() {
            this.documents_type.delete()
            this.$router.push(this.parent_page)
        },
    },
    async mounted() {
        this.documents_type = await DocumentsType.find(this.$route.params.id)
    },
}
</script>

<style lang="scss">
</style>
